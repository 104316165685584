import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GetPlacesViewItem } from 'edu-application/dist';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-place-info',
  templateUrl: './place-info.component.html',
  styleUrls: ['./place-info.component.scss']
})
export class PlaceInfoComponent implements OnInit, OnChanges {
  @Input() place: GetPlacesViewItem;
  constructor() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.place = changes.place.currentValue;
    this.place = {
      ...this.place,
      timing: this.place.timing.map(t => {
        return {
          ...t,
          time: new Date(t.time)
        }
      })
    }
  }

  ngOnInit(): void {
    this.place = {
      ...this.place,
      timing: this.place.timing.map(t => {
        return {
          ...t,
          time: new Date(t.time)
        }
      })
    }
  }

  getTime(time: Date): string {
    return time.toLocaleTimeString('en', {
      formatMatcher: 'best fit',
      hour: '2-digit',
      minute: '2-digit',
      timeZone : 'Africa/Cairo'
    })
  }

  getDay(date: Date): string {
    if(date == undefined) {
      return 'Schedule'
    }
    return  `Schedule starting from ${new Date(date).toLocaleDateString('en', {
      day: '2-digit',
      month: 'long',
      weekday: 'short'
    })}`
  }

  isICTeam() {
    return environment.org == "ICTeam"
  }

}
