import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StudentRegistrationRequest } from 'edu-application/dist/registration/student-registration/student-registration.request';
import { GetPlacesViewItem } from 'edu-application/dist'
import { GetRegistrantsViewItem } from 'edu-application/dist/registration/view-registrants/view-registrants.viewmodel';
import { AcceptRegistrantRequest } from 'edu-application/dist/registration/accept-registrant/accept-registrant.request';
import { GetGroupViewItem } from 'edu-application/dist/groups/get-groups/get-group.viewmodel';
import { EditRegistrantRequest } from 'edu-application/dist/registration/edit-registrant/edit-registrant.request'

import { environment } from 'src/environments/environment';
import { StudentRegistrationViewmodel } from 'edu-application/dist/registration/student-registration/student-registration.viewmodel';
@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  register(registerationObject: StudentRegistrationRequest): Promise<StudentRegistrationViewmodel> {
    return this._client.post<StudentRegistrationViewmodel>(`${environment.backend_url}/registrants`, registerationObject).toPromise();
  }

  getPlaces(): Promise<GetPlacesViewItem[]> {
    return this._client.get<GetPlacesViewItem[]>(`${environment.backend_url}/places`).toPromise();
  }

  getGroups(): Promise<GetGroupViewItem[]> {
    return this._client.get<GetGroupViewItem[]>(`${environment.backend_url}/groups`).toPromise();
  }

  getAllRegistrant(): Promise<GetRegistrantsViewItem[]> {
    return this._client.get<GetRegistrantsViewItem[]>(`${environment.backend_url}/registrants`).toPromise();
  }

  acceptRegistrant(request: AcceptRegistrantRequest): Promise<void> {
    return this._client.post<void>(`${environment.backend_url}/registrants/accept`, request).toPromise();
  }

  editRegistant(request: EditRegistrantRequest): Promise<void> {
    return this._client.put<void>(`${environment.backend_url}/registrants`, request).toPromise();
  }

  deleteRegistrant(id: string): Promise<void> {
    return this._client.delete<void>(`${environment.backend_url}/registrants/${id}`).toPromise();
  }
  constructor(
    private _client: HttpClient
  ) { }
}
