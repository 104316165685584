<link rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,1,0" />
<loading-spinner *ngIf="this.registrants == null"></loading-spinner>
<div *ngIf="this.registrants != null && this.registrants.length != 0">
    <div class="registrants-header">
        <h2>Our Registrants</h2>
    </div>
    <div class="light-form two-items">
        <mat-form-field appearance="standard">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
        </mat-form-field>
        <div class="buttons-container">
            <button mat-stroked-button (click)="this.saveRegistrants()">Export Filtered Registrants</button>
            <button mat-stroked-button (click)="this.exportContacts()">Export Contacts</button>
        </div>
    </div>
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8 light-form" matSort>
        <!-- <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay"> -->
        <!-- <th mat-header-cell *matHeaderCellDef> {{column}} </th>
            <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
        </ng-container> -->
        <ng-container matColumnDef="fullName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Full Name</th>
            <td mat-cell *matCellDef="let element">{{element.fullName}}</td>
        </ng-container>
        <ng-container matColumnDef="session">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Session</th>
            <td mat-cell *matCellDef="let element">{{element.session}}</td>
        </ng-container>
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.state == 'accepted'" class="material-symbols-outlined">check_circle</span>
                <span *ngIf="element.state == 'pending'" class="material-symbols-outlined">pending_actions</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="place">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Place</th>
            <td class="phone-cell" mat-cell *matCellDef="let element">{{element.place}}</td>
        </ng-container>
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td class="expanded-cell" mat-cell *matCellDef="let element"
                [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <app-registrant-card [element]="element"></app-registrant-card>
                    <button (click)="this.openEditDialog(element)" color="white" mat-stroked-button>Edit</button>
                    <button (click)="this.openAcceptRegistrant(element)" *ngIf="element.state != 'accepted'"
                        mat-stroked-button color="white">Accept</button>
                    <button (click)="this.deleteRegistrant(element)" *ngIf="element.state == 'pending'" mat-stroked-button color="white">
                      <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    <mat-paginator #paginator [pageSize]="75" [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons
        aria-label="Select page of periodic elements"></mat-paginator>
</div>
