import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GetGroupViewItem } from 'edu-application/dist/groups/get-groups/get-group.viewmodel';
import { ViewStudentsViewItem } from 'edu-application/dist/students/view-students/view-students.viewmodel';
import { BehaviorSubject } from 'rxjs';
import { StudentsService } from '../../students/students.service';
import { SuspendStudentComponent } from '../../students/suspend-student/suspend-student.component';
import { TransferStudentComponent } from '../../students/transfer-student/transfer-student.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StudentScheduleComponent } from '../student-schedule/student-schedule.component';

@Component({
  selector: 'app-student-card',
  templateUrl: './student-card.component.html',
  styleUrls: ['./student-card.component.scss']
})
export class StudentCardComponent implements OnInit {
  @Input('student') student: ViewStudentsViewItem;
  @Input('suspensionSubject') suspensionSubject: BehaviorSubject<ViewStudentsViewItem>;
  @Input('groups') allGroups: GetGroupViewItem[];
  constructor(private matDialog: MatDialog,
    private service: StudentsService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  openSuspensionDialog(student: ViewStudentsViewItem) {
    this.matDialog.open(SuspendStudentComponent, {
      data: {
        student: student,
        suspensionSubject: this.suspensionSubject
      },
      width: '40vw'
    })
  }
  isLoading: boolean = false;
  async activateAccount() {
    this.isLoading = true;
    try {
      let suspension = await this.service.usnsuspend(this.student.userName);
      this.suspensionSubject.next({
        ...this.student,
        isSuspended: suspension.isSuspended
      })
    } catch (e) {

    } finally {
      this.isLoading = false;
    }
  }

  openTransferStudentDialog(student: ViewStudentsViewItem) {
    this.matDialog.open(TransferStudentComponent, {
      data: {
        groups: this.allGroups,
        student: student,
        suspensionSubject: this.suspensionSubject
      },
      width: '40vw'
    })
  }

  async resetDevices() {
    this.isLoading = true;
    try {
      await this.service.resetDevices(this.student.userName);
      this.student = {
        ...this.student,
        devices: []
      };
      this.snackBar.open(`Reset devices for ${this.student.userName} successfully`, '', { duration: 2000 })
    } catch (e) {
      this.snackBar.open(`Failed to reset devices of ${this.student.userName}`, '', { duration: 2000 })
    } finally {
      this.isLoading = false;
    }
  }

  async resetPassword() {
    this.isLoading = true;
    try {
      await this.service.resetPassword(this.student.userName);
      this.snackBar.open(`Reset password for ${this.student.userName} successfully`, '', { duration: 2000 })
    } catch (e) {
      this.snackBar.open(`Failed to reset password of ${this.student.userName}`, '', { duration: 2000 })
    } finally {
      this.isLoading = false;
    }
  }

  openScheduleDialog() {
    this.matDialog.open(StudentScheduleComponent, {
      data: this.student,
      maxHeight: '80vh',
      maxWidth: '80vw',
      width: '600px',
      height: '1000px'
    })
  }
}
