import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewStudentsViewItem } from 'edu-application/dist/students/view-students/view-students.viewmodel';
import { BehaviorSubject } from 'rxjs';
import { StudentsService } from '../students.service';

@Component({
  selector: 'app-suspend-student',
  templateUrl: './suspend-student.component.html',
  styleUrls: ['./suspend-student.component.scss']
})
export class SuspendStudentComponent implements OnInit {
  isLoading: boolean = false;
  suspensionNoteControl: FormControl = new FormControl('', [Validators.required]);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { student: ViewStudentsViewItem, suspensionSubject: BehaviorSubject<ViewStudentsViewItem> },
    private dialogRef: MatDialogRef<SuspendStudentComponent>,
    private service: StudentsService
  ) { }

  ngOnInit(): void {

  }

  async suspend() {
    this.isLoading = true;
    try {
      let suspended = await this.service.suspend(this.data.student.userName, this.suspensionNoteControl.value);
      this.data.suspensionSubject.next({
        ...this.data.student,
        isSuspended: suspended.isSuspended,
        suspensionNote: suspended.suspensionNote
      })
      this.dialogRef.close();
    } catch (e) {

    } finally {
      this.isLoading = false;
    }
  }
}
