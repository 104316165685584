import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from '../login/login.service';

@Injectable()
export class ResponseTokenInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      filter(event => event instanceof HttpResponse),
      tap((event: HttpResponse<any>) => {
        if (event.headers.has('Authorization')) {
          let token = event.headers.get('Authorization').split('Bearer ')[1]
          this.loginService.refreshToken(token);
          return;
        }
      })
    );
  }
}
