import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IcteamLandingComponent } from './icteam-landing/icteam-landing.component';
import { LandingHeaderComponent } from './landing-header/landing-header.component';
import { FirstSectionComponent } from './first-section/first-section.component';
import { AboutComponent } from './about/about.component';
import { TeamComponent } from './team/team.component';
import { SchoolsComponent } from './schools/schools.component';
import { AboutItemComponent } from './about-item/about-item.component';
import { FooterComponent } from './footer/footer.component';
import { LandingRoutingModule } from './landing-routing.module';

@NgModule({
  declarations: [
    IcteamLandingComponent,
    LandingHeaderComponent,
    FirstSectionComponent,
    AboutComponent,
    TeamComponent,
    SchoolsComponent,
    AboutItemComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    LandingRoutingModule
  ]
})
export class ICTeamLandingModule { }
