import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { IcteamGuard } from "../icteam.guard";
import { IcteamLandingComponent } from './icteam-landing/icteam-landing.component';

const routes: Route[] = [
  {
    path: '',
    component: IcteamLandingComponent,
    canActivate : [IcteamGuard]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingRoutingModule { }