import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ThemisRoute } from 'src/app/navbar/edumono-route';

@Component({
  selector: 'edu-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss']
})
export class HeaderTitleComponent implements OnInit {
  @Input() route: ThemisRoute;
  @Input() darkMode: boolean;
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.iconRegistry.addSvgIcon(this.route.name, this.sanitizer.bypassSecurityTrustResourceUrl(this.route.image));
  }

}
