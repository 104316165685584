import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import * as marked from "marked"

@Component({
  selector: 'markdown-view',
  templateUrl: './markdown-viewer.component.html',
  styleUrls: ['./markdown-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MarkdownViewerComponent implements OnInit, OnChanges {
  @Input('data') data: string;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    var md = marked.setOptions({});
    this.convertedData = md.parse(this.data)
  }
  convertedData: string;

  ngOnInit(): void {

  }
}
