<div class="team-content">
    <div class="title">
        <img src="../../../assets/landing/group.png" width="70px" />
    </div>
    <h4>Our team has always been our defining trait. With more than a 100 members all eager to give their all to the
        students. <br />The team starts by our three wonderful teachers: </h4>
    <div id="komy-mobile" class="team-column">
        <img src="../../assets/teachers/komy.jpg" alt="Mostafa El Komy" />
        <h1 class="teacher-name">Mostafa El Komy</h1>
        <h2 class="teacher-title">El Coach</h2>
        <p class="teacher-info">
            The man of the hour, El Coach, and the leader of the whole team.
            He has graduated from Cairo University majoring in Computer Science.
            He started teaching ICT in 2010, and had a vision for the future. This vision lead him to found
            ICTeam.
        </p>
    </div>
    <div id="safty" class="team-column">
        <img src="../../assets/teachers/safty.jpg" />
        <h1 class="teacher-name">Omar El Safty</h1>
        <h2 class="teacher-title">Head of Theory</h2>
        <p class="teacher-info">
            One of the earliest members to join ICTeam he started working with us in 2011.
            He graduated from AUC majoring in Computer Science and now leads the theoretical team with a lot of
            innovative
            ideas.</p>
    </div>
    <div id="komy" class="team-column">
        <img src="../../assets/teachers/komy.jpg" alt="Mostafa El Komy" />
        <h1 class="teacher-name">Mostafa El Komy</h1>
        <h2 class="teacher-title">El Coach</h2>
        <p class="teacher-info">
            The man of the hour, El Coach, and the leader of the whole team.
            He has graduated from Cairo University majoring in Computer Science.
            He started teaching ICT in 2010, and had a vision for the future. This vision lead him to found
            ICTeam.
        </p>
    </div>
    <div id="hosso" class="team-column">
        <img src="../../assets/teachers/hossam.jpg" />
        <h1 class="teacher-name">Hossam Badawy</h1>
        <H2 class="teacher-title">Head of Practical </H2>
        <p class="teacher-info">
            After joining the team in 2013 he immediately stood out as one of the most compelling teachers in the team.
            He graduated from GUC, majoring in Computer Science. He now leads the practical team with a few tricks up
            his
            sleeves.
        </p>
    </div>
</div>