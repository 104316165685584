<button mat-fab color="red" [matMenuTriggerFor]="menu" class="mat-elevation-z0">
    {{this.initials}}
</button>
<mat-menu #menu="matMenu">
    <mat-card class="example-card mat-elevation-z0">
        <button mat-fab color="red" class="mat-elevation-z0">
            {{this.initials}}
        </button>
        <h2>{{name}}</h2>
        <mat-chip-list class="chip-list">
            <mat-chip class="chip-font" *ngIf="this.info.studentInfo != null">{{this.info.studentInfo.mainGroup.name}}
            </mat-chip>
        </mat-chip-list>
        <!-- <mat-card-content>
            <themis-points-view></themis-points-view>
        </mat-card-content> -->
        <mat-card-actions>
            <button (click)='this.logout()' mat-stroked-button color="black">
                <mat-icon>logout</mat-icon>
                Logout
            </button>
        </mat-card-actions>
    </mat-card>
</mat-menu>