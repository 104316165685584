<h1 mat-dialog-title>{{this.data.fullName}} Schedule</h1>
<div mat-dialog-content>
    <loading-spinner *ngIf="this.isloading"></loading-spinner>
    <div *ngIf="!this.isloading" class="light-form">
        <edu-admin-timings *ngIf="this.studentTimings.length != 0" [timings]="this.studentTimings"></edu-admin-timings>
        <p *ngIf="this.studentTimings.length == 0">No Timings</p>
        <hr />
        <h2>Excuses</h2>
        <p *ngIf="this.allTimings.length == 0">No Timings to excuse</p>
        <div *ngIf="this.allTimings.length != 0">
            <mat-form-field class="form" appearance="outline">
                <mat-label>Excuses</mat-label>
                <mat-select [formControl]="this.selectedTimings">
                    <mat-option [value]="timing" *ngFor="let timing of this.allTimings">Day {{timing.classNumber}} -
                        {{this.getDay(timing.day)}}
                        - {{this.getTime(timing.time)}}</mat-option>
                </mat-select>
                <mat-hint>Add Extra timing for this student</mat-hint>
            </mat-form-field>
        </div>
    </div>
</div>
<div>
    <button (click)="this.addExcuse()" mat-button>Add Excuse</button>
    <button mat-dialog-close mat-button>Cancel</button>
</div>