<div class="trustees-container">
    <h2>Trusted by</h2>
    <div class="images-container">
        <img src="../../../assets/trustees/eshra7ly.png" />
        <img src="../../../assets/trustees/elite.png" />
        <img src="../../../assets/trustees/quatro.png" />
        <img src="../../../assets/trustees/ig core.png" />
        <img src="../../../assets/trustees/bashaer.png" />
        <img src="../../../assets/trustees/ebis.png" />
        <img src="../../../assets/trustees/continental.png" />
        <img src="../../../assets/trustees/nis.png" />
        <img src="../../../assets/trustees/noor.png" />
        <img src="../../../assets/trustees/manarat.png" />
        <img src="../../../assets/trustees/narmer.png" />
        <img src="../../../assets/trustees/manor.png" />
        <img src="../../../assets/trustees/stars.png" />
        <img src="../../../assets/trustees/kis.png" />
        <img src="../../../assets/trustees/kvs.png" />
        <img src="../../../assets/trustees/eshra7ly.png" />
        <img src="../../../assets/trustees/elite.png" />
        <img src="../../../assets/trustees/quatro.png" />
        <img src="../../../assets/trustees/ig core.png" />
        <img src="../../../assets/trustees/bashaer.png" />
        <img src="../../../assets/trustees/ebis.png" />
        <img src="../../../assets/trustees/continental.png" />
        <img src="../../../assets/trustees/nis.png" />
        <img src="../../../assets/trustees/noor.png" />
        <img src="../../../assets/trustees/manarat.png" />
        <img src="../../../assets/trustees/narmer.png" />
        <img src="../../../assets/trustees/manor.png" />
        <img src="../../../assets/trustees/stars.png" />
        <img src="../../../assets/trustees/kis.png" />
        <img src="../../../assets/trustees/kvs.png" />
    </div>
</div>