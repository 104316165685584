import { BehaviorSubject, Observable } from "rxjs";

export class CompilerColorBloc {
    private static _instance: CompilerColorBloc;
    static CreateBloc() {
        if (CompilerColorBloc._instance == null) {
            CompilerColorBloc._instance = new CompilerColorBloc();
        }
        return CompilerColorBloc._instance;
    }
    private constructor() {
        this._isDarkMode = new BehaviorSubject(null);
        this._isHackerMode = new BehaviorSubject(null);
    }
    private _isDarkMode: BehaviorSubject<boolean>;

    private _isHackerMode: BehaviorSubject<boolean>;

    public get darkModeStream(): BehaviorSubject<boolean> {
        return this._isDarkMode;
    }

    public get hackerModeStream(): BehaviorSubject<boolean> {
        return this._isHackerMode;
    }
}