import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UploadFileViewmodel } from 'edu-application/dist/files/upload-file/upload-file.viewmodel';
@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private client: HttpClient) { }

  uploadFile(file: any) {
    let formData = new FormData();
    formData.append('file', file);
    return this.client.post<UploadFileViewmodel>(`${environment.backend_url}/files/upload/simple`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
}
