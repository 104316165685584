import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { EnabledStateChange, ImageEvents } from './image-modes';

@Component({
  selector: 'ngx-image-button',
  templateUrl: './image-button.component.html',
  styleUrls: ['./image-button.component.scss'],
  animations: [EnabledStateChange]
})
export class ImageButtonComponent implements OnInit {

  @Input('src') sourceImage!: string;
  @Input('selectedImage') selectedImage: string | null = null;
  @Input('hoverImage') hoverImage: string | null = null;
  @Input('selected') selected: boolean = false;
  source = '';

  constructor() {

  }

  ngOnInit(): void {
    this.source = this.sourceImage;
  }

  setImage(event: ImageEvents) {
    if(this.selected && event != ImageEvents.Selected) {
      return
    }
    switch (event) {
      case ImageEvents.Normal:
        this.source = this.sourceImage;
        break;
      case ImageEvents.Hover:
        if (this.hoverImage != null) {
          this.source = this.hoverImage as string;
        }
        break;
      case ImageEvents.Selected:
        if (this.selectedImage != null) {
          this.source = this.selectedImage as string;
        }
        break;
      default:
        break;
    }
  }

  toggle() {
    this.selected = true;
    this.setImage(this.selected ? ImageEvents.Selected : ImageEvents.Normal) 
  }

}

