<h1 mat-dialog-title>Suspending {{this.data.student.userName}}</h1>
<loading-spinner *ngIf="this.isLoading"></loading-spinner>
<div mat-dialog-content class="light-form center" *ngIf="!this.isLoading">
    <p>{{this.data.student.fullName}}</p>
    <p class="student-group">{{this.data.student.group.name}}</p>
    <mat-form-field class="form-field" appearance="outline">
        <input [formControl]="this.suspensionNoteControl" matInput />
        <mat-label>Suspension Note</mat-label>
        <mat-hint>Ex. A student has not yet completed his payment</mat-hint>
    </mat-form-field>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
</div>
<div align="end">
    <button mat-dialog-close mat-button>Cancel</button>
    <button (click)="this.suspend()" mat-button>Suspend</button>
</div>