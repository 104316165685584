<div class="main-container">
    <div id="text-container">
        <h1>
            Master Information & Communication Technology
        </h1>
        <p>Using our latest technologies and innovations to access tons of videos and materials during your IGCSE ICT journey</p>
    </div>
    <div id="image-container">
        <img src="../../../assets/landing/webinar.svg"/>
    </div>
</div>
<landing-schools></landing-schools>