import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetPointsThresholdsViewItem } from 'edu-application/dist/assignments/get-points-threshold-for-assignment/get-points-threshold-for-assignment.viewmodel';
import { GetStudentPointsViewmodel } from 'edu-application/dist/points/get-student-points/get-student-points.viewmodel';
import { LeaderboardViewItem } from 'edu-application/dist/points/get-leaderboard/get-leaderboard.viewmodel';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PointsService {

  constructor(
    private client: HttpClient
  ) { }

  async getThresholdsForAssignment(assignmentId: string): Promise<GetPointsThresholdsViewItem[]> {
    return this.client.get<GetPointsThresholdsViewItem[]>(`${environment.backend_url}/points/thresholds/${assignmentId}`).toPromise();
  }

  async getStudentPoints(userName: string): Promise<GetStudentPointsViewmodel> {
    return this.client.get<GetStudentPointsViewmodel>(`${environment.backend_url}/points/students/${userName}`).toPromise();
  }

  async getLeaderboard() : Promise<LeaderboardViewItem[]> {
    return this.client.get<LeaderboardViewItem[]>(`${environment.backend_url}/points/leaderboard`).toPromise();
  }
  
  async requestLeaderboardRefresh() : Promise<void> {
    return this.client.get<void>(`${environment.backend_url}/points/leaderboard/generate`).toPromise();
  }
}
