import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfoResponse } from 'edu-auth/dist/responses/user-info-response';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'profile-button',
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss']
})
export class ProfileButtonComponent implements OnInit {

  constructor(
    private _loginService: LoginService,
    private _router: Router
  ) { }
  name: string;
  info: UserInfoResponse
  ngOnInit(): void {
    this.info = this._loginService.userInfo
    this.name = this.info.fullName;
  }

  public get initials(): string {
    if (this.name == null) {
      return "";
    }
    let fullName = this.name.trim();
    let names = fullName.split(' ');
    return names[0][0] + names[names.length - 1][0];
  }

  async logout() {
    await this._loginService.logout();
    this._router.navigate(['/login']);
  }
  async goToProfile() {
    this._router.navigate(['/portal/students/dashboard']);
  }

}
