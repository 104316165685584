import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { AccessTokenResponse, AccessTokenSuccessResponse, AccessTokenErrorResponse } from 'edu-auth/dist/responses/access-token.response';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { AccessTokenRequest, PasswordGrantTokenRequest } from 'edu-auth/dist/request/access-token.request';
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isLoading: boolean = false;

  loginStream: BehaviorSubject<AccessTokenRequest> = new BehaviorSubject(null);
  deviceId: string;
  environment = environment;
  isNavigating: boolean = false;
  constructor(
    private _loginService: LoginService,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar
  ) { }

  redirectUrl: string;
  ngOnInit(): void {
    // Check the redirection url 
    if (this.activatedRoute.snapshot.queryParamMap.has('redirect_to')) {
      this.redirectUrl = this.activatedRoute.snapshot.queryParamMap.get('redirect_to');
    }
    // Check if the query params contain an access token
    if (this.activatedRoute.snapshot.queryParamMap.has('access_token')) {
      this._loginService.setTokens({ access_token: this.activatedRoute.snapshot.queryParamMap.get('access_token'), scope: 'web', token_type: 'access_token', expires_in: 3600 });
      this._loginService.getUserInfo().then(s => {
        this.redirect();
      });

      return;
    }
    if (this._loginService.isLoggedIn && this._loginService.hasInfo) {
      this.redirect();
    }
    // Check if the user is logged in but does not have info
    if (this._loginService.isLoggedIn && !this._loginService.hasInfo) {
      // if (!this._loginService.hasInfo) {
      //   this.isNavigating = true;
      //   this.getUserInfo();
      // } else {
      //   this.redirect();
      // }
      this._loginService.logout();
    }

    if (!this._loginService.isLoggedIn) {
      this.loginStream.subscribe((value) => {
        if (value == null) {
          return;
        }
        this.login(value);
      })
    }
  }
  public async login(request: AccessTokenRequest) {
    try {
      this.isLoading = true;
      let token = await this._loginService.login(request as PasswordGrantTokenRequest);
      let info = await this._loginService.getUserInfo();
      this.redirect();
    } catch (e) {
      this.snackBar.open(e.message, "dismiss", { duration: 3000, verticalPosition: "top", horizontalPosition: "right" })
    } finally {
      this.isLoading = false;
    }
  }

  private redirect() {
    if (this.redirectUrl != null) {
      this._router.navigateByUrl(this.redirectUrl);
    } else {
      if (this._loginService.userInfo.type == 'team' && this._loginService.userInfo.teamMemberInfo.type !== 'quality') {
        this._router.navigate(['/portal/dashboard']);
      } else {
        this._router.navigate(['/portal/home'])
      }
    }
  }


}
