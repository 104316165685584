import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouterModule, Routes } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { ViewRegistrantsComponent } from './registration/view-registrants/view-registrants.component';
import { AuthGuard } from './shared/auth.guard';
import { HeadGuardGuard } from './shared/head-guard.guard';
import { TeamGuardGuard } from './shared/team-guard.guard';
import { StarWarsIntroComponent } from './translator/star-wars-intro/star-wars-intro.component';
import { EduNavbarComponent } from './navbar/edu-navbar/edu-navbar.component';
import { ViewProfilesComponent } from './profile/profile-admin-page/view-profiles.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./icteam-landing/landing.module').then(m => m.ICTeamLandingModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'portal',
    component: EduNavbarComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'materials',
        loadChildren: () => import('./materials/materials.module').then(m => m.MaterialsModule)
      },
      {
        path: 'videos',
        loadChildren: () => import('./videos/videos.module').then(m => m.VideosModule)
      },
      {
        path: 'registrants',
        component: ViewRegistrantsComponent,
        canActivate: [TeamGuardGuard]
      },
      {
        path: 'meetings',
        loadChildren: () => import('./meeting-links/meeting-links.module').then(m => m.MeetingLinksModule),
        canActivate: [TeamGuardGuard],
        canActivateChild: [TeamGuardGuard]
      },
      {
        path: 'submissions',
        loadChildren: () => import('./assignments/assignments-with-routing.module').then(m => m.AssignmentWithRoutingModule)
      },
      {
        path: 'zoom',
        loadChildren: () => import('./zoom/zoom.module').then(m => m.ZoomModule)
      },
      // {
      //   path: 'pseudocode',
      //   loadChildren: () => import('./translator/translator.module').then(m => m.TranslatorModule)
      // },
      {
        path: 'students',
        loadChildren: () => import('./students/students.module').then(m => m.StudentsModule)
      },
      {
        path: 'calls',
        loadChildren: () => import('./calls/calls.module').then(m => m.CallsModule),
        canActivate: [TeamGuardGuard]
      },
      {
        path: 'payments',
        loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule),
        canActivate: [HeadGuardGuard]
      },
      {
        path: 'team',
        loadChildren: () => import('./team/team.module').then(m => m.TeamModule),
        canActivate: [TeamGuardGuard]
      },
      {
        path: 'leaderboard',
        loadChildren: () => import('./points/points-with-routing.module').then(m => m.PointsWithRouting),
        canActivate: [HeadGuardGuard]
      },
      {
        path: 'milestones',
        loadChildren: () => import('./milestones/milestones.module').then(m => m.MilestonesModule),
        canActivate: [TeamGuardGuard]
      },
      {
        path: 'lectures',
        loadChildren: () => import('./lectures/lectures.module').then(m => m.LecturesModule)
      },
      {
        path: 'questions',
        loadChildren: () => import('./posts/posts.module').then(m => m.PostsModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'variants',
        loadChildren: () => import('./variants/variants.module').then(m => m.VariantsModule),
        canActivate: [HeadGuardGuard]
      },
      {
        path: 'announcements',
        loadChildren: () => import('./announcements/announcements.module').then(m => m.AnnouncementsModule)
      },
      {
        path: 'session-management',
        loadChildren: () => import('./session-management/session-management.module').then(m => m.SessionManagementModule),
        canActivate: [HeadGuardGuard]
      },
      {
        path: 'timings',
        loadChildren: () => import('./timings/timings.module').then(m => m.TimingsModule),
        canActivate: [HeadGuardGuard]
      }
    ]
  },
  {
    path: 'starwars',
    component: StarWarsIntroComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'invite',
    loadChildren: () => import('./invitations/invitations.module').then(m => m.InvitationsModule)
  },
  {
    path: 'performance',
    loadChildren: () => import('./performance/performance.module').then(m => m.PerformanceModule)
  },
  {
    path: '**',
    redirectTo: 'login',
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
