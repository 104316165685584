import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'edumono-detail-item',
  templateUrl: './detail-item.component.html',
  styleUrls: ['./detail-item.component.scss']
})
export class DetailItemComponent implements OnInit {
  @Input() content: string;
  @Input() image: string;
  @Input() property : string;
  constructor() { }

  ngOnInit(): void {
  }

}
