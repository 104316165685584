import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ProfileButtonComponent } from './profile-button/profile-button.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { RamadanHeaderComponent } from './ramadan-header/ramadan-header.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavbarItemComponent } from './navbar-item/navbar-item.component';
import { EduNavbarComponent } from './edu-navbar/edu-navbar.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    NavbarComponent,
    ProfileButtonComponent,
    RamadanHeaderComponent,
    NavbarItemComponent,
    EduNavbarComponent
  ],
  imports: [
    SharedModule,

    CommonModule,
    BrowserModule,
    MatSnackBarModule,
    MatMenuModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatSidenavModule,
    RouterModule
  ]
})
export class NavbarModule { }
