import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-star-wars-intro',
  templateUrl: './star-wars-intro.component.html',
  styleUrls: ['./star-wars-intro.component.scss', './starwarsintro.scss']
})
export class StarWarsIntroComponent implements OnInit, OnDestroy {

  constructor(private _router : Router) { }
  ngOnDestroy(): void {
    let audioTag = document.getElementById("star-wars");
    document.body.removeChild(audioTag);
  }

  ngOnInit(): void {
    this.playAudio();
  }

  private playAudio() {
    let audioTag = new Audio();
    audioTag.id = "star-wars";
    audioTag.src = 'assets/sound/star-wars.mp3';
    audioTag.loop = false;
    audioTag.autoplay = true;

    audioTag.addEventListener("canplay", () => {
    });

    audioTag.addEventListener("ended", () => {

    });
    audioTag.addEventListener('ended', () => {
      this._router.navigate(['/portal/code']);
    })
    document.body.appendChild(audioTag);
    audioTag.load();
    audioTag.play();
  }

}
