import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'action-button',
  templateUrl: './action_button.component.html',
  styleUrls: ['./action_button.component.scss'],
})
export class ActionButtonComponent implements OnInit {
  @Input() image: string;
  @Input() text: string;
  @Input() buttonStyle: string;
  constructor() { }

  ngOnInit(): void {
  }

}
