import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ICTeamLandingModule } from './icteam-landing/landing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegistrationModule } from './registration/registration.module';
import { PostHeadersInterceptor } from './interceptors/headers.interceptors';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginModule } from './login/login.module';
import { NavbarModule } from './navbar/navbar.module';
import { IcteamGuard } from './icteam.guard';
import { AccessTokenInterceptor } from './shared/access-token.interceptor';
import { ResponseInterceptor } from './shared/response.interceptor';
import { IcteamPrankComponent } from './prank/icteam-prank/icteam-prank.component';
import { ResponseTokenInterceptor } from './shared/response-token.interceptor';
import { SharedModule } from './shared/shared.module';
import { StudentsService } from './students/students.service';
import { GroupsService } from './shared/groups.service';
import { AssignmentsService } from './assignments/assignments.service';
import { AssignmentsPageComponent } from './assignments-page/assignments-page.component';

@NgModule({
  declarations: [
    AppComponent,
    IcteamPrankComponent,
    AssignmentsPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ICTeamLandingModule,
    RegistrationModule,
    LoginModule,
    AppRoutingModule,
    NavbarModule,
    HttpClientModule,
    SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: PostHeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseTokenInterceptor, multi: true },
    IcteamGuard,
    StudentsService,
    GroupsService,
    AssignmentsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
