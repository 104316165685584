import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ViewStudentsViewItem } from 'edu-application/dist/students/view-students/view-students.viewmodel';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'student-selector',
  templateUrl: './student-selector.component.html',
  styleUrls: ['./student-selector.component.scss']
})
export class StudentSelectorComponent implements OnInit {
  @Input('control') studentControl: FormControl;
  @Input('students') students: ViewStudentsViewItem[];
  filteredStudents: Observable<ViewStudentsViewItem[]>;
  constructor() { }
  private _filterStudents(value: string): ViewStudentsViewItem[] {
    const filterValue = value.toLowerCase();
    return this.students.filter(student => student.fullName.toLowerCase().includes(filterValue) || student.userName.toLowerCase().includes(filterValue));
  }
  ngOnInit(): void {
    this.filteredStudents = this.studentControl.valueChanges.pipe(
      startWith(''),
      map(student => (student ? this._filterStudents(student) : this.students.slice())),
    );
  }

}
