import { Component, Input, OnInit } from '@angular/core';
import { ThemisRoute } from '../edumono-route';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar-item',
  templateUrl: './navbar-item.component.html',
  styleUrls: ['./navbar-item.component.scss']
})
export class NavbarItemComponent implements OnInit {
  @Input() route: ThemisRoute;
  @Input() collapsed: boolean = false;
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.iconRegistry.addSvgIcon(this.route.name, this.sanitizer.bypassSecurityTrustResourceUrl(this.route.image));
  }

  goToPage(): void {
    window.open(`https://translator${environment.domain}` + this.route.route, '_blank')
  }
}
