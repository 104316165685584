<div class="wave">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
    </svg>
</div>
<div class="main-container">
    <landing-about-item text="schools trusting us with teaching IGCSE ICT on their campuses" image="assets/about/school.png" smallNumber="15+"></landing-about-item>
    <landing-about-item text="students have joined us over the years since our founding in 2010" image="assets/about/students.png" smallNumber="10k+"></landing-about-item>
    <landing-about-item text="assistants dedicated to help you throughout the session" image="assets/about/helping.png" smallNumber="100+"></landing-about-item>
    <landing-about-item text="at least 80% of our students have scored A or higher" image="assets/about/solvent.png" smallNumber="80%"></landing-about-item>
</div>