<div class="instructions" *ngIf="!this.isLoading">
    <ol>
        <li>Download the mobile application</li>
        <li>Tap the QR Code icon and select <strong>Website Login</strong></li>
        <li>Point your device to the QR Code below</li>
    </ol>
</div>
<div class="qr-code" *ngIf="!this.isLoading">
    <qr-code *ngIf="this.deviceId != null" value={{this.deviceId}} errorCorrectionLevel="H" size="300"
        centerImageSrc="../../assets/icteam-logo-black-total.jpg" centerImageSize="0"></qr-code>
</div>
<div *ngIf="this.isLoading">
    <loading-spinner></loading-spinner>
</div>