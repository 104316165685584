import { Component, OnInit } from '@angular/core';
import { CompilerColorBloc } from 'src/app/translator/compiler-color.bloc';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ramadan-header',
  templateUrl: './ramadan-header.component.html',
  styleUrls: ['./ramadan-header.component.scss']
})
export class RamadanHeaderComponent implements OnInit {
  clicks = 0;
  timeout = null;
  constructor() { }

  get isDark() {
    return CompilerColorBloc.CreateBloc().darkModeStream.value == true
  }

  getSource() {
    if (environment.org == "ICTeam") {
      return "../../assets/ramadan/lanterns.png";
    }
    if (this.isDark) {
      return "../../assets/ramadan/lanterns_stars_dark.png"
    }
    return "../../assets/ramadan/lanterns_stars_moon.png"
  }

  ngOnInit(): void {
  }

  getClick() {
    if (environment.org !== "ICTeam") {
      return;
    }
    this.clicks++;
    if (!this.timeout) {
      this.timeout = setTimeout(
        () => {
          this.clicks = 0;
        }, 1000)
    }
    if (this.clicks === 3) {
      let player = <HTMLAudioElement>document.getElementById("ramadan-audio");
      player.play();
      this.clicks = 0;
      this.timeout = null;
    }
  }

}
