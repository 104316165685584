import { Component, Input, OnInit } from '@angular/core';
import { BaseTimingViewmodel } from 'edu-application/dist/timings/base/timings.viewmodel';

@Component({
  selector: 'edu-admin-timings',
  templateUrl: './admin-timings.component.html',
  styleUrls: ['./admin-timings.component.scss']
})
export class AdminTimingsComponent implements OnInit {
  @Input() timings: BaseTimingViewmodel[];
  constructor() { }

  ngOnInit(): void {
  }

  getDay(day: number) {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day]
  }

  getTime(time: Date) {
    time = new Date(time);
    return time.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  getClassNumbers() {
    return [...new Set([...this.timings.map(s => s.classNumber)])].sort((a, b) => a - b);
  }


  filterTiming(classNumber: number) {
    return this.timings.filter(s => s.classNumber === classNumber);
  }

}
