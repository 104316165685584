import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetGroupViewItem } from 'edu-application/dist/groups/get-groups/get-group.viewmodel';
import { AcceptRegistrantRequest } from 'edu-application/dist/registration/accept-registrant/accept-registrant.request';
import { GetRegistrantsViewItem } from 'edu-application/dist/registration/view-registrants/view-registrants.viewmodel';
import { BehaviorSubject } from 'rxjs';
import { RegistrationService } from '../registration.service';

@Component({
  selector: 'app-accept-registrant',
  templateUrl: './accept-registrant.component.html',
  styleUrls: ['./accept-registrant.component.scss']
})
export class AcceptRegistrantComponent implements OnInit {
  groupControl: FormControl = new FormControl('', [Validators.required]);
  isLoading: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { reg: GetRegistrantsViewItem, groups: GetGroupViewItem[], subject: BehaviorSubject<GetRegistrantsViewItem> }, private service: RegistrationService, public dialogRef: MatDialogRef<AcceptRegistrantComponent>, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }
  async accept() {
    this.isLoading = true;
    let request: AcceptRegistrantRequest = {
      id: this.data.reg.id,
      group: this.groupControl.value
    };
    if (this.groupControl.invalid) {
      return;
    }
    try {
      let viewmodel = await this.service.acceptRegistrant(request);
      let student = {
        ...this.data.reg,
        state: 'accepted'
      }
      this.data.subject.next(student);
      this.dialogRef.close();
    } catch (e) {
      this._snackBar.open(`Error cannot accept registrant, try again later`, '', { duration: 5000 })
    } finally {
      this.isLoading = false;
    }
  }
}
