import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'landing-first-section',
  templateUrl: './first-section.component.html',
  styleUrls: ['./first-section.component.scss']
})
export class FirstSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
