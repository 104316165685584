export class ThemisRoute {
    constructor(private _name: string, private _admin: boolean, private _image?: string) {

    }

    public get name() { return this._name }
    public get admin() { return this._admin }
    public get image(): string { return this._image ?? 'assets/navbar/icon.png'; }
    public get route() {
        if (this._admin && this._name == "Meetings")
            return `/portal/meetings/admin`;
        return `/portal/${this.name.toLowerCase()}`
    }
}
