<mat-drawer-container class="example-container" [hasBackdrop]="true">
    <mat-drawer #drawer mode="over" [opened]="this.sideNavOpen" (closed)="this.sideNavOpen = false">
        <div class="vertical-navbar">
            <div class="logo-container">
                <button mat-icon-button (click)="this.toggleSideNav(false)"><mat-icon>keyboard_backspace</mat-icon></button>
                <img class="logo" [src]="this.logoImage" />
            </div>
            <div class="routes">
                <app-navbar-item *ngFor="let route of this.routes" [route]="route"></app-navbar-item>
            </div>
            <hr/>
            <profile-button></profile-button>
        </div>
    </mat-drawer>
    <mat-drawer-content>
        <div class="header-grid" [class.student-grid]="!this.isAdmin" [class.dark-mode]='this.isDark' [class.hacker-mode]='this.isHacker'>
            <div class="logo-container" align="end">
                <button mat-icon-button (click)="this.toggleSideNav(true)"><mat-icon>menu</mat-icon></button>
                <img class="logo" [src]="this.logoImage" />
            </div>
            <ul *ngIf="!this.isAdmin">
                <li *ngFor="let route of routes">
                    <a [routerLink]='route.route' [routerLinkActive]="['active']">{{route.name}}</a>
                </li>
            </ul>
            <profile-button class="profile-button"></profile-button>

        </div>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>