import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  @Output('file') file = new EventEmitter<File>();
  constructor() { }

  ngOnInit(): void {
  }

  public fileOver(event) {

  }

  public fileLeave(event) {
  }  
  @Input('fileName') fileName: string;

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      this.file.emit(file);
    }
  }
}
