<h2 mat-dialog-title>Editing {{data.reg.fullName}}</h2>
<mat-dialog-content class="light-form mat-typography">
    <mat-form-field class="icteam-form-field" appearance="outline">
        <input matInput type="text" [formControl]='this.fullNameController' />
        <mat-icon matSuffix>badge</mat-icon>
        <mat-label>Full Name</mat-label>
        <mat-hint>Write at least 4 names</mat-hint>
        <mat-error *ngIf="this.fullNameController.hasError('required')">
            Full name is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="this.fullNameController.hasError('pattern')">
            Full name must have at least 4 names
        </mat-error>
    </mat-form-field>
    <mat-form-field class="icteam-form-field" appearance="outline">
        <input matInput type="email" [formControl]='this.emailController' />
        <mat-icon matSuffix>alternate_email</mat-icon>
        <mat-label>Email</mat-label>
        <mat-hint>Write an email that you have access to</mat-hint>
        <mat-error *ngIf="this.emailController.hasError('required')">
            Email is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="this.emailController.hasError('email')">
            {{this.emailController.value}} is not a valid email
        </mat-error>
        <mat-error *ngIf="this.emailController.hasError('mismatch')">
            Must match the value of confirmation
        </mat-error>
        <mat-error *ngIf="this.emailController.hasError('duplicate')">
            Email {{this.emailController.value}} is already registered
        </mat-error>
    </mat-form-field>
    <br />
    <mat-form-field class="icteam-form-field" appearance="outline">
        <ngx-mat-intl-tel-input [formControl]='this.phoneNumberController'
            [onlyCountries]="['eg', 'sa', 'ae', 'qa', 'kw', 'lb', 'om']" [enablePlaceholder]="true"
            [enableSearch]="true" name="phone">
        </ngx-mat-intl-tel-input>
        <mat-label>Student Phone Number</mat-label>
        <mat-hint>A WhatsApp enabled phone number</mat-hint>
        <mat-icon matSuffix>phone</mat-icon>
    </mat-form-field>
    <mat-form-field class="icteam-form-field" appearance="outline">
        <ngx-mat-intl-tel-input [formControl]='this.parentPhoneNumberController'
            [onlyCountries]="['eg', 'sa', 'ae', 'qa', 'kw', 'lb', 'om']" [enablePlaceholder]="true"
            [enableSearch]="true" name="phone">
        </ngx-mat-intl-tel-input>
        <mat-label>Parent Phone Number</mat-label>
        <mat-icon matSuffix>escalator_warning</mat-icon>
        <mat-hint>One of your parent's phone numbers</mat-hint>
    </mat-form-field>
    <mat-form-field class="icteam-form-field" appearance="outline">
        <mat-label>Group</mat-label>
        <mat-select [formControl]='this.groupController'>
            <mat-option *ngFor="let group of this.data.places" [value]="group.placeName">
                {{group.placeName}} - {{group.placeArea}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="this.groupController.hasError('required')">Group is
            <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <mat-form-field class="icteam-form-field" appearance="outline">
        <mat-label>School</mat-label>
        <mat-hint>Write the name of the school you're enrolled at</mat-hint>
        <input [readonly]="!this.canChangeSchool()" matInput type="text" [formControl]='this.schoolController' />
        <mat-error *ngIf="this.schoolController.hasError('required')">School is
            <strong>required</strong>
        </mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [disabled]="this.isLoading" mat-dialog-close>Cancel</button>
    <button (click)="this.saveEdits()" mat-button [disabled]="this.isLoading" cdkFocusInitial>Save</button>
</mat-dialog-actions>