import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {

  constructor(private _loginService : LoginService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isApiUrl = request.url.startsWith(environment.backend_url);
    if (this._loginService.isLoggedIn && isApiUrl) {
      let token = this._loginService.access_token;
      let refresh_token = this._loginService.refresh_token;
        request = request.clone({
            setHeaders: {
                'authorization': `Bearer ${token}`
            }
        });
        if (refresh_token) {
            request = request.clone({
                setHeaders: {
                    'refresh-token': `${refresh_token}`
                }
            })
        }
    }
    return next.handle(request);
  }
}
