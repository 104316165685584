<div class="student-info">
    <p class="student-name">{{student.fullName}}</p>
    <p class="group-name">{{student.group.name}}</p>
    <div class="phone-container">
        <mat-icon>school</mat-icon>
        <p>{{student.schoolName}}</p>
    </div>
    <div class="phone-container">
        <mat-icon>account_circle</mat-icon>
        <phone-number [number]="student.phoneNumber"></phone-number>
    </div>
    <div class="phone-container">
        <mat-icon>escalator_warning</mat-icon>
        <phone-number [number]="student.parentPhoneNumber"></phone-number>
    </div>
    <div class="vertical-center phone-container">
        <mat-icon>email</mat-icon>
        <p>{{student.email}}</p>
    </div>
    <div class="vertical-center phone-container" *ngIf="student.isSuspended">
        <mat-icon>block</mat-icon>
        <p>{{student.suspensionNote}}</p>
    </div>
</div>