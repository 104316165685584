<h1 mat-dialog-title>Transfer Student</h1>
<div mat-dialog-content class="light-form">
    <mat-form-field appearance="outline">
        <mat-label>Choose group</mat-label>
        <mat-select [formControl]="this.groupControl">
            <mat-option *ngFor="let group of this.data.groups" [value]="group.id">
                {{group.placeName}} - {{group.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button [disabled]="this.isLoading" (click)="this.transfer()" mat-button>Transfer</button>
</div>