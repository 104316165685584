import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'edumono-submission-state',
  templateUrl: './submission-state.component.html',
  styleUrls: ['./submission-state.component.scss']
})
export class SubmissionStateComponent implements OnInit {

  @Input('state') state: string;
  constructor() { }
  icon: string;
  ngOnInit(): void {
    this.icon = this.getIcon();
  }

  getIcon(): string {
    switch (this.state) {
      case "submitted":
        return "check_circle";
      case "corrected":
        return "grading";
      case "reviewed":
        return "grading";
      case "not_started":
        return "cancel";
      case "started":
        return "pending";
    }
    return "menu";
  }

  getState(): string {
    switch (this.state) {
      case "not_started":
        return "Not Started";
      case "started":
        return "In Progress";
      case "submitted":
        return "Submitted";
      case "corrected":
        return "Corrected";
      case "reviewed":
        return "Reviewed ✅"
      default:
        return "";
    }
  }


}
