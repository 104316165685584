import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormControlName, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { GetPlacesViewItem } from 'edu-application/dist';
import { GetRegistrantsViewItem } from 'edu-application/dist/registration/view-registrants/view-registrants.viewmodel';
import { BehaviorSubject } from 'rxjs';
import { RegistrationService } from '../registration.service';
@Component({
  selector: 'app-edit-registrant',
  templateUrl: './edit-registrant.component.html',
  styleUrls: ['./edit-registrant.component.scss']
})
export class EditRegistrantComponent implements OnInit {
  isLoading: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { reg: GetRegistrantsViewItem, places: GetPlacesViewItem[], subject: BehaviorSubject<GetRegistrantsViewItem> }, private service: RegistrationService, public dialogRef: MatDialogRef<EditRegistrantComponent>) {
    this.fullNameController.setValue(data.reg.fullName);
    this.emailController.setValue(data.reg.email);
    this.phoneNumberController.setValue(data.reg.phoneNumber);
    this.parentPhoneNumberController.setValue(data.reg.parentPhoneNumber);
    this.groupController.setValue(data.reg.place.split('-')[0].trim())
    this.schoolController.setValue(this.data.reg.schoolName);
  }

  ngOnInit(): void {
  }
  fullNameController: FormControl = new FormControl('', [Validators.required, Validators.pattern(/[a-zA-Z]{2,}\s{1}[a-zA-Z]{2,}\s{1}[a-zA-Z]{2,}\s{1}[a-zA-Z]{2,}/)])
  emailController: FormControl = new FormControl('', [Validators.email]);
  phoneNumberController: FormControl = new FormControl('', []);
  parentPhoneNumberController: FormControl = new FormControl('', [])
  groupController: FormControl = new FormControl('', []);
  schoolController: FormControl = new FormControl('', []);

  saveEdits() {
    this.isLoading = true;
    let request = {
      id: this.data.reg.id,
      newName: this.fullNameController.value,
      newEmail: this.emailController.value,
      newPhoneNumber: this.phoneNumberController.value,
      newParentPhoneNumber: this.parentPhoneNumberController.value,
      newPlace: this.groupController.value,
      newSchoolName: this.schoolController.value
    };
    let newPlace = this.data.places.find(p => p.placeName == this.groupController.value);
    this.service.editRegistant(request).then(s => {
      this.data.subject.next({
        id: this.data.reg.id,
        fullName: this.fullNameController.value,
        email: this.emailController.value,
        phoneNumber: this.phoneNumberController.value,
        parentPhoneNumber: this.parentPhoneNumberController.value,
        place: `${newPlace.placeName} -  ${newPlace.placeArea}`,
        schoolName: this.schoolController.value,
        session: this.data.reg.session,
        state: this.data.reg.state,
        gender: this.data.reg.gender,
        date: this.data.reg.date,
      } as GetRegistrantsViewItem)
      this.dialogRef.close();
    }).catch(e => {
    })
      .finally(() => {
        this.isLoading = false;
      })
  }

  canChangeSchool(): boolean {
    let currentPlace = this.data.places.find(p => p.placeName == this.groupController.value)
    return currentPlace && currentPlace.placeType == 'private'
  }
}
