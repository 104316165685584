import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'edu-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  @Input() collapsed : boolean;
  constructor() { }

  get logo() {
    return environment.assets.logo;
  }

  get isICTeam() {
    return environment.org == "ICTeam";
  }

  get org() {
    return environment.org;
  }
  ngOnInit(): void {
  }

}
