<!-- Place in Body where you'd like intro to appear -->
<div class="star-wars-intro">

  <!-- Blue Intro Text -->
  <p class="intro-text" style="text-align: left;">
    A Long time ago in an IGCSE session far, far away...
  </p>

  <!-- Logo Image or Text goes in here -->
  <h2 class="main-logo">
    <img src="../../../assets/csteam-logo--total-white.png">
  </h2>

  <!-- All Scrolling Content Goes in here -->
  <div class="main-content">

    <div class="title-content">
      <p class="content-header">EPISODE MMXXI<br />THE FALL OF COVID</p>

      <br>

      <p class="content-body center">
        The galaxy has heard a mysterious broadcast, CSTeam's battalion have conquered the Computer Science Galaxy. The battalion was training in secret for 8 months.
        <br />
        <br/>
        The group of young programmers where commanded by a young general and have surrounded the galaxy's examination board.
        <br/>
        The battalion have succeeded in their mission causing panic in the high ranks of the Computer Science Galaxy, which allowed them to control the remaining parts of it.
        <br />
        <br/>
        486 Programmers scored a 9
        <br />
        <br />

        Another 112 Programmers scored an 8
        <br />
        <br />
        That is 80% worth of A*, leaving the galaxy wondering are there any other grades on the scale?
      </p>

    </div>
  </div>
</div>