<div class="registration-container">
    <div class="left-part">
        <img [src]="environment.assets.logo" class="logo-pic" />
        <h5>You are <strong>3</strong> steps away from logging into our online platform</h5>
        <img src="assets/login/login.svg" width="90%" />
    </div>
    <div class="right-part">
        <div class="instructions">
            <h1>Login to the system</h1>
        </div>
        <!-- <app-qr-login *ngIf="!this.isLoading && !this.isNavigating" [loginStream]="this.loginStream"></app-qr-login> -->
        <app-form-login *ngIf="!this.isLoading" [loginStream]="this.loginStream"></app-form-login>
        <div class="loading-container" *ngIf="this.isLoading">
            <loading-spinner></loading-spinner>
        </div>
        <div *ngIf="!this.isLoading">
            <h4 class="">Download the app from:</h4>
            <div class="store-badges">
                <div>
                    <a target="_applestore" [href]="environment.iosLink"><img class="img-store" alt="Download on the App Store"
                            src="assets/login/app-store.svg" /></a>
                </div>
                <div>
                    <a target="_googleplay" [href]='environment.androidLink'><img class="img-store" alt='Get it on Google Play'
                            src='assets/login/google-play-badge.png' /></a>
                </div>
            </div>
        </div>
    </div>
</div>