import { Component, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { v4 as uiid } from 'uuid';

@Component({
  selector: 'ngx-recorder',
  templateUrl: './recorder.component.html',
  styleUrls: ['./recorder.component.scss']
})
export class RecorderComponent implements OnInit {
  @Input() recordingSubject: BehaviorSubject<File> = new BehaviorSubject(null);
  @Input() playingSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  recorder: MediaRecorder | undefined;
  chunks: any[] = [];
  stream: MediaStream | undefined;
  state: "Idle" | "Recording" = "Idle";
  source: string = "";
  constructor() { }

  ngOnInit(): void {
  }

  private _processRecording() {
  }

  toggleRecording() {
    if (this.state == 'Idle') {
      this.startRecording()
    } else if (this.state == 'Recording') {
      this.stopRecording();
    }
  }

  private startRecording() {
    this.source = "";
    navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(s => {
      this.stream = s;
      this.recorder = new MediaRecorder(this.stream)
      this.recordingSubject.next(null);
      this.recorder.start()
      this.playingSubject.next('new-recording')
      this.state = 'Recording'
      this.recorder.onstop = this._processRecording;
      this.recorder.addEventListener('dataavailable', (data) => {
        let blob = new Blob([data.data], { type: 'audio/mpeg' });
        if (data.data.size == 0) {
          return;
        }
        let link = window.URL.createObjectURL(blob);
        this.recordingSubject.next(new File([blob], `voice-note-${uiid()}.mp3`));
        this.source = link;
        this.chunks.push(data.data)
      })
    })
  }

  private stopRecording() {
    if (this.recorder == undefined || this.stream == undefined) {
      return;
    } else {
      for (let track of this.stream.getTracks()) {
        track.stop();
      }
      this.recorder.stop();
      this.state = 'Idle'
    }
  }
}
