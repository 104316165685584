<div class="button-upload">
    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>

    <div class="file-upload">
        {{fileName || "Upload a new file"}}

        <button mat-mini-fab color="red" class="upload-btn" (click)="fileUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
    </div>
</div>