import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { LoginService } from 'src/app/login/login.service';
import { PointsService } from 'src/app/points/points.service';

@Component({
  selector: 'edu-points-summary',
  templateUrl: './points-summary.component.html',
  styleUrls: ['./points-summary.component.scss']
})
export class PointsSummaryComponent implements OnInit {
  @Input() type: "points" | "trophy";
  @Input() darkMode : boolean;
  constructor(
    private loginService: LoginService,
    private dashboardService: DashboardService,
    private pointsService : PointsService
  ) { }
  image: string;
  isLoading: boolean = false;
  number: string;
  ngOnInit(): void {
    this.image = this.type == "trophy" ? "assets/home/trophy.png" : "assets/home/storm.png";
    if(this.type == "trophy") {
      this.getTrophies();
    } else {
      this.getPoints();
    }
  }

  async getTrophies() {
    try {
      this.isLoading = true;
      let trophies = await this.dashboardService.getStudentTrophies(this.loginService.userInfo.userName);
      this.number = trophies.filter(s => s.progress.isEarned).length.toString();
    } catch (e) {

    } finally {
      this.isLoading = false;
    }
  }

  async getPoints() {
    try {
      this.isLoading = true;
      let points = await this.pointsService.getStudentPoints(this.loginService.userInfo.userName);
      this.number = points.total.toString();
    } catch(e) {
      
    } finally {
      this.isLoading = false;
    }
  }
}
