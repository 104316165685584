import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { GetRegistrantsViewItem } from 'edu-application/dist/registration/view-registrants/view-registrants.viewmodel';

@Component({
  selector: 'app-registrant-card',
  templateUrl: './registrant-card.component.html',
  styleUrls: ['./registrant-card.component.scss']
})
export class RegistrantCardComponent implements OnInit, AfterViewInit {
  @Input('element') element: GetRegistrantsViewItem;
  dateString: string;
  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    this.dateString = new Date(this.element.date).toDateString();

  }

}
