
import { AccessTokenResponse } from 'edu-auth/dist/responses/access-token.response';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from './login.service';

export class SocketService {

  disconnect() {
    this.socket.disconnect();
  }
  constructor(private loginService: LoginService, private socket: Socket) {
    this.socket.on('devices', (args) => {
      this.loginService.setTokens(args as AccessTokenResponse);
      this.subject.next(args);
      this.socket.disconnect();
    });
  }


  subject: BehaviorSubject<AccessTokenResponse> = new BehaviorSubject(null);
  public async getDeviceId(): Promise<string> {
    this.socket.connect();
    return new Promise((resolve) => {
      this.socket.on('connect', () => {
        resolve(this.socket.ioSocket.id);
      })
      this.socket.on('disconnect', () => {
        this.socket.connect();
      })
    })
  }
}
