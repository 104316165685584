import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'error-vector',
  templateUrl: './no-assignments.component.html',
  styleUrls: ['./no-assignments.component.scss']
})
export class NoAssignmentsComponent implements OnInit {
  @Input('image') src : string = "assets/assignments/not_found.png";
  @Input('message') message : string = "We've looked everywhere no assignments yet";
  constructor() { }

  ngOnInit(): void {
  }

}
