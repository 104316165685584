import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-money-card',
  templateUrl: './money-card.component.html',
  styleUrls: ['./money-card.component.scss']
})
export class MoneyCardComponent implements OnInit {
  @Input('titleIcon') titleIcon : string;
  @Input('content') content : string;
  @Input('subtitle') subtitle : string;
  @Input('color') color : string;

  class: string;
  imageClass: string;
  constructor() { }

  ngOnInit(): void {
    this.class = `money-card padding-1 ${this.color}`
    if(this.color == 'white'){
      this.imageClass = `image-white`;
    }
    else{
      this.imageClass = `image`;
    }
  }

}
