import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-voice-note-player',
  templateUrl: './voice-note-player.component.html',
  styleUrls: ['./voice-note-player.component.scss']
})
export class VoiceNotePlayerComponent implements OnInit {
  @Input() src;
  @Input() playingSrc: BehaviorSubject<string>;
  constructor() { }
  interval: NodeJS.Timeout;
  ngOnInit(): void {
    this.player = document.getElementById(this.src);
    this.interval = setInterval(() => {
      this.player = document.getElementById(this.src);
      this.currentTime = this.player.currentTime;
      this.duration = this.player.duration;
      this.isPlaying = !this.player.paused;
      if (this.currentTime == this.player.duration) {
        this.currentTime = 0;
      }
    }, 300)
    this.playingSrc.subscribe(src => {
      if (src == null) {
        return;
      }
      if (src !== this.src) {
        this.pause();
      }
    })
  }
  player: any;
  isPlaying: boolean = false;
  play() {
    this.player.play();
    this.isPlaying = true;
    this.playingSrc.next(this.src);
  }

  pause() {
    this.player.pause();
    this.isPlaying = false;
  }

  currentTime: number;
  duration: number;

  getId(): string {
    return this.src;
  }
}
