<div class="header">
    <div id="navigation">
        <a (click)="this.navtigateToAbout()" class="nav-item">About Us</a>
        <a (click)="this.navigateToTeam()" class="nav-item">Team</a>
    </div>

    <div class="logo-item header-item">
        <img src="../../../assets/icteam-logo-no-slogan.png" />
    </div>

    <div id="portal-nav">
        <a (click)="this.navigateToPortal()" class="nav-item">Login</a>
        <a (click)="this.navigateToRegister()" id="register-button">Register</a>
    </div>
</div>