import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements OnInit {
  @Input('number') phoneNumber : string;
  constructor() { }

  ngOnInit(): void {
  }

}
