import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss']
})
export class LandingHeaderComponent implements OnInit {

  constructor(private router : Router) { }

  ngOnInit(): void {
  }

  navigateToPortal() {
    this.router.navigate(['/login']);
  }

  navigateToRegister() {
    this.router.navigate(['/register'])
  }

  navtigateToAbout(){
    document.getElementById('about').scrollIntoView(
      {
        behavior : 'smooth'
      }
    );
  }

  navigateToTeam() {
    document.getElementById("team").scrollIntoView({
      behavior : 'smooth'
    });
  }

}
