import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'edu-profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.scss']
})
export class ProfileAvatarComponent implements OnInit {
  @Input() image: string;
  @Input() name: string;
  constructor() { }
  initials: string;
  ngOnInit(): void {
    let names = this.name.split(' ');
    let initials = names[0][0] + '' + names[names.length - 1][0]
    this.initials = initials.toUpperCase();
  }

}
