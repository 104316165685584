export const environment = {
    production: true,
    backend_url: 'https://csteam-backend-39l57.ondigitalocean.app/edu-mono2',
    backend_socket: 'wss://csteam-backend-39l57.ondigitalocean.app/edu-mono2',
    assets: {
        logo: "assets/csteam-logo.png",
        favIcon: "favicon-cs.ico"
    },
    org: "CSTeam",
    "domain" : ".csteam-elsafty.com",
    iosLink: "https://apps.apple.com/us/app/csteam-app/id1488517644",
    androidLink: "https://play.google.com/store/apps/details?id=com.csteamelsafty.mobile&hl=en&gl=US"
};