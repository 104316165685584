import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'deadline-cell',
  templateUrl: './deadline-cell.component.html',
  styleUrls: ['./deadline-cell.component.scss']
})
export class DeadlineCellComponent implements OnInit {
  @Input() deadline: string;
  constructor() { }

  ngOnInit(): void {
  }

}
