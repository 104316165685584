import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AccessTokenErrorResponse } from 'edu-auth/dist/responses/access-token.response';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoginService } from '../login.service';
import { SocketService } from '../socket.service';
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-qr-login',
  templateUrl: './qr-login.component.html',
  styleUrls: ['./qr-login.component.scss']
})
export class QrLoginComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  deviceId: string = '';
  @Input() loginStream: BehaviorSubject<boolean>;
  timeInterval: Subscription;
  private _loginService: SocketService
  constructor(
    private loginService: LoginService
  ) {
    this._loginService = new SocketService(this.loginService, new Socket({ url: environment.backend_url }));
  }
  ngOnDestroy(): void {
    this.loginStream.next(false);
    this._loginService.disconnect();
  }

  async tryLogin() {
    this.isLoading = true;
    try {
      this.deviceId = await this._loginService.getDeviceId()
      this._loginService.subject.subscribe(value => {
        if (value == null) {
          return;
        }
        if ((value as AccessTokenErrorResponse).error_description != null) {
          this.loginStream.next(false);
          return;
        }
        this.loginStream.next(true);
      });
    } catch (e) {
    } finally {
      this.isLoading = false;
    }

  }

  ngOnInit(): void {
    this.tryLogin();
  }

}
