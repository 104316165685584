import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddExtraTimingExcuseRequest } from 'edu-application/dist/students/add-extra-timing/add-extra-timing.request';
import { BaseTimingViewmodel } from 'edu-application/dist/timings/base/timings.viewmodel';
import { SetGroupTimingsRequest } from 'edu-application/dist/timings/set-group-timings/set-group-timings.request';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TimingsService {
  constructor(private client: HttpClient) { }

  async getTimings() {
    return this.client.get<BaseTimingViewmodel[]>(`${environment.backend_url}/timings`).toPromise();
  }

  async addTimings(request: BaseTimingViewmodel) {
    return await this.client.post<BaseTimingViewmodel>(`${environment.backend_url}/timings`, request).toPromise();
  }

  async getGroupTimings(id: string) {
    return this.client.get<BaseTimingViewmodel[]>(`${environment.backend_url}/timings/groups/${id}`).toPromise();
  }

  async setGroupTimings(request: SetGroupTimingsRequest) {
    return this.client.post(`${environment.backend_url}/timings/groups`, request).toPromise();
  }

  async getStudentTimings(username : string) {
    return this.client.get<BaseTimingViewmodel[]>(`${environment.backend_url}/students/timings/${username}`).toPromise()
  }

  async setStudentExcuse(request : AddExtraTimingExcuseRequest) {
    return this.client.post(`${environment.backend_url}/students/timings`, request).toPromise();
  }
}
