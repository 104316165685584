import { FormGroup, ValidatorFn } from "@angular/forms";

export function equalValueValidator(targetKey: string, toMatchKey: string): ValidatorFn {
  return (group: FormGroup): { [key: string]: any } => {
    const target = group.controls[targetKey];
    const toMatch = group.controls[toMatchKey];

    if (!target || !toMatch) {
      return null;
    }
    if (toMatch.errors && !toMatch.errors.mismatch) {
      return null;
    }
    if ((target.value as string).toLowerCase() !== (toMatch.value as string).toLowerCase()) {
      toMatch.setErrors({ mismatch: true });
      target.setErrors({ mismatch: true })
    } else {
      toMatch.setErrors(null);
      target.setErrors(null);
    }
    return null;
  };
}

export function differentValueValidator(targetKey: string, toMatchKey: string): ValidatorFn {
  return (group: FormGroup): { [key: string]: any } => {
    const target = group.controls[targetKey];
    const toMatch = group.controls[toMatchKey];

    if (!target || !toMatch) {
      return null;
    }
    if (toMatch.errors && !toMatch.errors.mismatch) {
      return null;
    }
    if ((target.value as string).toLowerCase() === (toMatch.value as string).toLowerCase()) {
      toMatch.setErrors({ matches: true });
      target.setErrors({ matches: true })
    } else {
      toMatch.setErrors(null);
      target.setErrors(null);
    }
    return null;
  };
}
