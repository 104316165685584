import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private _loginService: LoginService,
    private _router: Router
  ) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin(state.url)
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin(state.url)
  }

  private checkLogin(redirectUrl: string): boolean {
    if (this._loginService.isLoggedIn && this._loginService.hasInfo) {
      return true;
    }
    this._loginService.logout();
    this.redirectApp(redirectUrl);
    return false;
  }
  redirectApp(redirectUrl: string): void {
    this._router.navigate(
      ['/login'], {
      queryParams: {
        redirect_to: redirectUrl.includes('login') ? undefined : redirectUrl
      }
    }
    );
  }


}
