<h2 mat-dialog-title>Accept {{data.reg.fullName}}</h2>
<mat-dialog-content class="light-form mat-typography">
    <mat-form-field appearance="outline">
        <mat-label>Choose group</mat-label>
        <mat-select [formControl]="this.groupControl">
            <mat-option *ngFor="let group of this.data.groups" [value]="group.id">
                {{group.placeName}} - {{group.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [disabled]="this.isLoading" mat-dialog-close>Cancel</button>
    <button (click)="this.accept()" mat-button [disabled]="this.isLoading" cdkFocusInitial>Accept</button>
</mat-dialog-actions>