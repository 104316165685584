import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'landing-about-item',
  templateUrl: './about-item.component.html',
  styleUrls: ['./about-item.component.scss']
})
export class AboutItemComponent implements OnInit {
  
  @Input('smallNumber') smallNumber : string;
  @Input('image') imageSource : string;
  @Input('text') text : string;
  constructor() { }
  ngOnInit(): void {
  }

}
