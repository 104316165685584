import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-whatsapp-link',
  templateUrl: './whatsapp-link.component.html',
  styleUrls: ['./whatsapp-link.component.scss']
})
export class WhatsappLinkComponent implements OnInit {
  @Input() title: string;
  @Input() link: string;
  constructor() { }

  ngOnInit(): void {
  }
  goToLink() {
    window.open(this.link, '_blank');
  }
}
