<div class="card-container">
    <img class="element-gender-image"
        [src]='element.gender == "male" ? "assets/registration/male.png" : "assets/registration/female.png"' />
    <div class="example-element-description">
        <p>{{element.fullName}}</p>
        <div class="phone-container">
            <p>Student:</p>
            <phone-number [number]="element.phoneNumber"></phone-number>
        </div>
        <div class="phone-container">
            <p>Parent:</p>
            <phone-number [number]="element.parentPhoneNumber"></phone-number>
        </div>
        <p>Email: {{element.email}}</p>
        <p>Date: {{dateString}}</p>
        <p>School: {{element.schoolName}}</p>
    </div>
</div>