<mat-form-field *ngIf="this.studentControl != null" class="example-form" appearance="outline">
    <mat-label>Student</mat-label>
    <input matInput aria-label="State" [matAutocomplete]="auto" [formControl]="this.studentControl"
        name="student-selector">
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let student of filteredStudents | async" [value]="student.userName">
            <span>{{student.fullName}}</span> |
            <small>Username: {{student.userName}}</small>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>