<div class="voice-note">
    <button *ngIf="!isPlaying" mat-button (click)='play()'>
        <mat-icon class="big">play_circle_outline</mat-icon>
    </button>
    <button *ngIf="isPlaying" mat-button (click)='pause()'>
        <mat-icon class="big">pause_circle_outline</mat-icon>
    </button>
    <div class="progress-indicator">
        <mat-progress-bar [value]='this.currentTime * 100 / this.duration'></mat-progress-bar>
    </div>
</div>
<audio autoplay [id]="this.src" webkit-playsinline="true" x-webkit-airplay="true" preload="auto">
    <source [src]='this.src' type="audio/mpeg">
</audio>