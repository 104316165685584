import { Component, Directive, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'edu-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  host: {
    'class': 'edu-card'
  },
  encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

/**
 * Title of a card, intended for use within `<mat-card>`. This component is an optional
 * convenience for one variety of card title; any custom title element may be used in its place.
 *
 * MatCardTitle provides no behaviors, instead serving as a purely visual treatment.
 */
@Directive({
  selector: `edu-card-title, [edu-card-title], [eduCardTitle]`,
  host: { 'class': 'edu-card-title' }
})
export class EduCardTitle { }

/**
 * Subtitle of a card, intended for use within. This component is an optional
 * convenience for one variety of card title; any custom title element may be used in its place.
 *
 * MatCardSubTitle provides no behaviors, instead serving as a purely visual treatment.
 */
@Directive({
  selector: `edu-card-subtitle, [edu-card-subtitle], [eduCardSubTitle]`,
  host: { 'class': 'edu-card-subtitle' }
})
export class EduCardSubtitle { }

