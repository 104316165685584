import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription, pipe } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { UploadService } from '../upload.service';

@Component({
  selector: 'simple-file-uploader',
  templateUrl: './simple-file-uploader.component.html',
  styleUrls: ['./simple-file-uploader.component.scss']
})
export class SimpleFileUploaderComponent implements OnInit {
  loadingFile: boolean = false;
  @Output() url : EventEmitter<string> = new EventEmitter();
  constructor(
    private service: UploadService
  ) { }

  ngOnInit(): void {
  }
  uploadProgress: number;
  uploadSub: Subscription;
  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file) {
      this.loadingFile = true;
      let observable = this.service.uploadFile(file)
      this.uploadSub = observable.subscribe(event => {
        if (event.type == HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(100 * (event.loaded / event.total));
        }
        if(event.type == HttpEventType.Response) {
          // this.reset()
          if(event.body.fileUrl) {
            this.url.emit(event.body.fileUrl);
          }
        }
      }, (error) => {
      }, () => this.reset())
      // const upload$ = this.http.post("/api/thumbnail-upload", formData);

      // upload$.subscribe();
    }
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
    this.loadingFile = false;
  }

  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.reset();
  }
}
