export enum ImageEvents {
    Normal,
    Hover,
    Selected
}

import {
    trigger,
    style,
    state,
    animate,
    transition,
} from '@angular/animations';

export const EnabledStateChange = [
    trigger('enabledStateChange', [
        transition('disabled => enabled', [
            animate(
                '200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                style({ transform: 'scale(1.05)' })
            ),
            animate(
                '200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                style({ transform: 'scale(1)' })
            ),
            animate('300ms'),
        ]),
    ]),
];