import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ViewStudentsViewItem } from 'edu-application/dist/students/view-students/view-students.viewmodel';
import { SuspendStudentRequest } from 'edu-application/dist/students/suspend-student/suspend-student.request';
import { SuspendStudentViewModel } from 'edu-application/dist/students/suspend-student/suspend-student.viewmodel';
import { TransferStudentRequest } from 'edu-application/dist/students/transfer-student/transfer-student.request';
import { TransferStudentViewmodel } from 'edu-application/dist/students/transfer-student/transfer-student.viewmodel';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class StudentsService {
  private static students: ViewStudentsViewItem[] = null;
  constructor(private client: HttpClient, private loginService: LoginService) { }

  async getStudents(): Promise<ViewStudentsViewItem[]> {
    if (StudentsService.students == null) {
      StudentsService.students = await this.client.get<ViewStudentsViewItem[]>(`${environment.backend_url}/students`).toPromise();
    }
    return StudentsService.students;
  }

  async getStudent(): Promise<ViewStudentsViewItem> {
    let userName = this.loginService.userInfo.userName
    return this.client.get<ViewStudentsViewItem>(`${environment.backend_url}/students/${userName}`).toPromise();
  }

  async suspend(userName: string, note: string): Promise<SuspendStudentViewModel> {
    let request: SuspendStudentRequest = {
      userName: userName,
      reason: note
    }
    return this.client.put<SuspendStudentViewModel>(`${environment.backend_url}/students/suspend`, request).toPromise();
  }

  async usnsuspend(userName: string): Promise<SuspendStudentViewModel> {
    let request: SuspendStudentRequest = {
      userName: userName
    }
    return this.client.put<SuspendStudentViewModel>(`${environment.backend_url}/students/unsuspend`, request).toPromise();
  }

  async transferStudent(userName: string, newGroup: string): Promise<TransferStudentViewmodel> {
    let request: TransferStudentRequest = {
      userName: userName,
      groupId: newGroup
    }
    return this.client.put<TransferStudentViewmodel>(`${environment.backend_url}/students/transfer`, request).toPromise();
  }

  async resetDevices(userName: string): Promise<void> {
    return this.client.put<void>(`${environment.backend_url}/students/reset/${userName}`, {}).toPromise();
  }

  async resetPassword(userName: string): Promise<void> {
    return this.client.put<void>(`${environment.backend_url}/students/resetpass/${userName}`, {}).toPromise();
  }
}
