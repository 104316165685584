import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetGroupViewItem } from 'edu-application/dist/groups/get-groups/get-group.viewmodel'
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  private static groups : GetGroupViewItem[];
  constructor(private _http : HttpClient) { }

  async getGroups() : Promise<GetGroupViewItem[]>{
    if(GroupsService.groups == null) {
      GroupsService.groups = await this._http.get<GetGroupViewItem[]>(`${environment.backend_url}/groups`).toPromise();
    }
    return GroupsService.groups;
  }
}
